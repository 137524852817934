<template>
  <div class="end mainWrap">
    <div class="endWrap p-0">
      <p class="text-center endMsg">第{{interimNo}}回進捗状況報告が完了しました。</p>
      <p class="text-center mb-4">申請番号： {{code}}</p>

      <div class="bottonArea flex flexCenter mt-5">
        <b-button
          class="bold"
          variant="primary"
          :to="`/top`">トップに戻る</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gasInterimRepEnd',
  components: {
  },
  data() {
    return {
      id: null,
      year: null,
      name: '',
    };
  },
  computed: {
    code() {
      return this.$store.state.gasApplicationInput.code;
    },
    interimNo() {
      return this.$store.state.gasApplicationInput.interimNo;
    },
  },
  methods: {
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .end{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .endWrap{
    width: 500px;
    padding: 30px;
  }

  .endMsg {
    font-size: 30px;
  }

  .endLabel {
    width: 70px;
    display: inline-block;
    background-color: #6E6E6E;
    color: #fff;
    font-weight: bold;
  }

  .endLabelSecond {
    width: 170px;
    display: inline-block;
  }

  .space {
    width: 15px;
    display: inline-block;
  }

  .sendRuleWrap {
    background-color: #F3F3F3;
  }

  .msg {
    padding-left: 45px;
  }

  .printWrap {
    border: solid 1px #0A8EA7;
    font-size: 14px;
    padding: 5px;
    border-radius: 6px;
    width: 140px;
    height: 34px;
    display: inline-flex;
    vertical-align: middle;
  }

  .printWrap:hover {
    text-decoration: none !important;
  }

  .printIcon {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: 4px;
  }
</style>
